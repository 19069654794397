* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

label {
  color: #475467;
  font-size: 0.8rem;
}

ul {
  list-style: none;
}

a {
  text-decoration: none !important;
}

th {
  font-size: 0.8rem;
  font-weight: 500;
}
.active-page{
  color: 'red';
}
.pagination{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
.pagination-container {
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}

.pagination-container input {
  width: 40px;
}

/* thead tr {
  background-color: #f7f7f7 !important;
} */

tr {
  cursor: pointer;
}

.light-button {
  background-color: transparent;
  border: 0.5px solid #585858;
  padding: 5px;
  font-size: 0.7rem;
  border-radius: 3px;
  font-weight: 500;
  color: #344054;
}

.gray-td {
  font-size: 0.8rem;
  color: #818181 !important;
}

.tr {
  border: 0px solid #fff !important;
}

.active {
  background-color: #003f66;
  font-weight: 600;
}

.search-mandate {
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 7px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 130px;
}

.round-profile {
  background-color: #007dcc;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 0.7rem;
  font-weight: 600;
}

.outer-circle {
  width: 150px;
  border-radius: 100%;
  height: 150px;
  background-color: #fbfdfe1e;
}

.search-mandate::placeholder {
  color: #fff !important;
  font-size: 0.7rem;
}

/* Typewriter loader */
.spinner {
  width: 70.4px;
  height: 70.4px;
  --clr: rgb(247, 197, 159);
  --clr-alpha: rgb(247, 197, 159,.1);
  animation: spinner 1.6s infinite ease;
  transform-style: preserve-3d;
}

.spinner > div {
  background-color: var(--clr-alpha);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 3.5px solid var(--clr);
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-35.2px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(35.2px);
}

@keyframes spinner {
  0% {
    transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
  }

  50% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
  }

  100% {
    transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
  }
}

.spin {
  animation: spin 1s linear infinite; /* You can adjust the animation duration and type */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.table-wrapper {
  margin-top: 10px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td,
.fl-table th {
  text-align: center;
  padding: 8px;
  max-width: 400px; /* Ensures that the cells don't exceed 400px */
  word-wrap: break-word; /* Allow content to wrap if it's too long */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for overflowed content */
}

.fl-table td {
  text-align: left;
  border-right: 1px solid #f8f8f8;
  font-size: 14px;
}

.fl-table thead th {
  color: #ffffff;
  background: #4FC3A1;
  font-size: 15px;
  font-weight: bold;
}

.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: rgb(0, 125, 204);
}

.fl-table tr:nth-child(even) {
  background: #f8f8f8;
}



/* Responsive */

@media (max-width: 767px) {
  .fl-table {
      display: block;
      width: 100%;
  }
  .table-wrapper:before{
      content: "Scroll horizontally >";
      display: block;
      text-align: right;
      font-size: 11px;
      color: white;
      padding: 0 0 10px;
  }
  .fl-table thead, .fl-table tbody, .fl-table thead th {
      display: block;
  }
  .fl-table thead th:last-child{
      border-bottom: none;
  }
  .fl-table thead {
      float: left;
  }
  .fl-table tbody {
      width: auto;
      position: relative;
      overflow-x: auto;
  }
  .fl-table td, .fl-table th {
      padding: 20px .625em .625em .625em;
      height: 60px;
      vertical-align: middle;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      width: 120px;
      font-size: 13px;
      text-overflow: ellipsis;
  }
  .fl-table thead th {
      text-align: left;
      border-bottom: 1px solid #f7f7f9;
  }
  .fl-table tbody tr {
      display: table-cell;
  }
  .fl-table tbody tr:nth-child(odd) {
      background: none;
  }
  .fl-table tr:nth-child(even) {
      background: transparent;
  }
  .fl-table tr td:nth-child(odd) {
      background: #F8F8F8;
      border-right: 1px solid #E6E4E4;
  }
  .fl-table tr td:nth-child(even) {
      border-right: 1px solid #E6E4E4;
  }
  .fl-table tbody td {
      display: block;
      text-align: center;
  }
}